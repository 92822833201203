export function removeExistingHighlights(content: string) {
  return content.replace(/<span class="highlight">(.*?)<\/span>/g, '$1')
}

// Decode HTML entities which come in highlights
function decodeHtmlEntities(text: string): string {
  const temp = document.createElement('textarea')
  temp.innerHTML = text
  return temp.value
}

export function applyHighlightsToWords(container: HTMLElement, highlights: string[], highlightId = ''): string {
  let content = container.innerHTML

  const escapeRegExp = (str: string): string => str.replace(/'/g, '"').replace(/[.*+?^${}()|[\]\\]/g, '\\$&')

  highlights.forEach((highlight) => {
    const decodedHighlight = decodeHtmlEntities(highlight)

    // Extract the sentence without <span> tags
    const rawSentence = decodedHighlight.replace(/<span class="highlight">(.*?)<\/span>/g, '$1')

    // Extract words that should be highlighted
    const matches = decodedHighlight.match(/<span class="highlight">(.*?)<\/span>/g)

    if (!matches) {
      return
    }

    // Ensure we replace occurrences only within the matching sentence
    content = content.replace(new RegExp(escapeRegExp(rawSentence)), (sentenceMatch) => {
      let modifiedSentence = sentenceMatch
      let foundFirstMatch = false // Track if we've already applied the ID

      matches.forEach((match) => {
        const wordToHighlight = match.replace(/<span class="highlight">|<\/span>/g, '')
        const escapedWord = escapeRegExp(wordToHighlight)

        // Matches the word but prevents duplicate wrapping while allowing words inside <a> tags
        const regex = new RegExp(`(?!<[^>]*?)\\b${escapedWord}\\b(?![^<]*?>)`, 'g')

        modifiedSentence = modifiedSentence.replace(regex, (match) => {
          if (/<span class="highlight">/.test(match)) {
            return match // Skip if already highlighted
          }

          if (!foundFirstMatch) {
            foundFirstMatch = true // Mark first match as found
            if (highlightId.length) { // Apply ID if provided for comment highlights
              return `<span id="${highlightId}"><span class="highlight">${match}</span></span>`
            }
            return `<span class="highlight">${match}</span>`
          }

          return `<span class="highlight">${match}</span>`
        })
      })

      return modifiedSentence
    })
  })

  return content
}
