<script setup lang="ts">
import type { SolrDoc } from '~/utils/solr/doc'
import { faArrowDownToBracket, faBookSection, faBuildingColumns, faCalendarDays, faSection } from '@fortawesome/pro-light-svg-icons'
import { useLawMetadata } from '~/composables/laws/useLawMetaData'

const props = defineProps<{ law: SolrDoc }>()

const meta = useLawMetadata(props.law)

const newTitle = computed(() => {
  return props.law.title?.replace(/^Lov/, `Lov ${meta.value?.lawDate}`)
})
</script>

<template>
  <section class="pt-m pb-xl">
    <div class="flex flex-col gap-xs w-full max-w-9xl mx-auto">
      <Callout v-if="meta?.isRepealed" variant="warning" title="Opphevet">
        <p>
          Denne loven er erstattet av <LawCommentaryLink v-if="meta.lawId" :law="meta.lawId" class="text-current">
            {{ newTitle }}.
          </LawCommentaryLink>
        </p>
      </Callout>
      <div class="flex gap-s items-center">
        <h2>{{ law.shortTitle ?? law.title }}</h2>
        <FavoriteButton />
      </div>
      <div class="flex flex-row max-lg:flex-col items-center max-lg:items-start gap-s">
        <div class="flex items-center gap-s">
          <Chip variant="outlined" color="carbon" :icon-left="faSection" size="small" class="text-primary">
            {{ law.id.toLocaleUpperCase() }}
          </Chip>
          <NuxtLink>
            <Chip :icon-left="faBookSection" variant="outlined" color="carbon" size="small" class="text-primary">
              Les om lovkommentaren
            </Chip>
          </NuxtLink>
          <Chip variant="outlined" color="carbon" :icon-left="faBuildingColumns" size="small" class="text-primary">
            {{ law.ministry }}
          </Chip>
          <Chip variant="outlined" color="carbon" :icon-left="faCalendarDays" size="small" class="text-primary">
            Sist endret {{ formatDate(law.lastChangeInForce || law.timestamp, { year: 'numeric', month: 'long', day: 'numeric' }) }}
          </Chip>
        </div>
        <div class="flex flex-1 justify-end items-center gap-xs">
          <Button variant="secondary" size="small" :icon="faArrowDownToBracket">
            Last ned
          </Button>
        </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang='postcss'>
:deep(aside) {
  svg {
    @apply text-carbon-500 !important;
  }
}
</style>
