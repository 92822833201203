import { array, nonEmpty, object, parse, pipe, string, transform } from 'valibot'

const KEY = 'lovId'

const paramsSchema = object({
  [KEY]: pipe(
    array(string()),
    transform(([rawLawId, ...parts]) => {
      const lawId = parse(pipe(string(), nonEmpty()), rawLawId).toLocaleLowerCase()
      const lawFragmentId = parts.filter(Boolean).join('/').toLocaleLowerCase()
      return {
        lawId,
        lawFragmentId,
        nodeId: [lawId, lawFragmentId].filter(Boolean).join('/'),
      }
    }),
  ),
})

export function useLawRouteParams() {
  const route = useRoute()

  return computed(() => parse(paramsSchema, route.params)[KEY])
}
