<script setup lang="ts">
import type { LawVariant } from '~/constants/law'
import type { SolrDoc } from '~/utils/solr/doc'
import { KsIcon } from '@aschehoug/kloss'
import { faArrowRight } from '@fortawesome/pro-light-svg-icons'
import { useLawRouteParams } from '~/composables/laws/useLawRouteParams'
import { NODE_TYPE_MAPPING } from '~/utils/solr/doc-utils'

const props = defineProps<{
  variant: LawVariant
  node: SolrDoc
  searchResult: { id: string, highlights: string[] } | null
  comments: Set<string>
}>()

const showComment = defineModel('show-comment', {
  default: false,
})

const params = useLawRouteParams()

const textRef = useTemplateRef<HTMLElement>('text')

const nodeId = computed(() => props.node.id.toLocaleLowerCase())

const hasCommentary = computed(() => props.comments.has(nodeId.value))

const meta = useLawMetadata(props.node)

const hasMeta = computed(() => {
  return Boolean(meta.value?.lawDate && meta.value?.lawNumber)
})

const lawDescription = computed(() => {
  if (!hasMeta.value || props.node.nodeType !== 'law') {
    return
  }

  return props.node.title?.replace(/^Lov/, `Lov ${meta.value?.lawDate} nr. ${meta.value?.lawNumber}`)
})

const isSelectedNode = computed(() => {
  return params.value.nodeId === nodeId.value
})

watch(() => props.searchResult, (searchResult) => {
  if (textRef.value) {
    textRef.value.innerHTML = removeExistingHighlights(textRef.value.innerHTML)

    if (props.node.id === searchResult?.id) {
      textRef.value.innerHTML = applyHighlightsToWords(textRef.value, searchResult.highlights)
    }
  }
})

watch([textRef, isSelectedNode], ([el, isSelected]) => {
  if (el && isSelected) {
    const lawTextContainer = document.getElementById('law-text-container')

    if (!lawTextContainer) {
      return
    }

    const pageScrollY = window.scrollY
    const rect = el.getBoundingClientRect()
    const containerRect = lawTextContainer.getBoundingClientRect()
    const offsetTop = rect.top - containerRect.top + lawTextContainer.scrollTop

    lawTextContainer.scrollTo({ top: offsetTop - 80, behavior: 'smooth' })

    // Scroll back to the original position after the scroll is done, use requestAnimationFrame for better timing
    requestAnimationFrame(() => {
      window.scrollTo({ top: pageScrollY, behavior: 'smooth' })
    })
  }
})

// Makes sure that lists are not wrapped in paragraphs
function handleBodyText(node: SolrDoc) {
  return node.nodeType === 'subsection' && !node.bodytext?.includes('<ol') && !node.bodytext?.includes('<ul')
}
</script>

<template>
  <div
    :id="node.id"
    ref="text"
    tabindex="0"
    class="
    flex flex-col items-stretch
    py-m pl-l gap-xs scroll-mt-3xl
    transition-all duration-300 ease-in-out
    border-b border-b-border-secondary
  "
    :class="{
      'bg-transparent xl:bg-surface-primary rounded-lg border-b-transparent': (isSelectedNode || showComment) && hasCommentary,
    }"
    :aria-label="node.title ?? undefined"
  >
    <div class="flex flex-col items-stretch pr-s" :class="node.nodeType !== 'law' ? 'gap-s' : 'gap-m'">
      <div class="flex justify-between items-center">
        <component :is="props.variant !== 'title' ? 'h5' : 'h4'" v-if="node.title" class="group-hover:underline group-focus:underline font-semibold">
          {{ node.title }}
        </component>
        <template v-if="hasCommentary">
          <LawCommentaryLink
            :law="nodeId"
            class="flex-none hidden xl:flex items-center justify-center rounded-lg h-8 w-8 focus:outline-none focus:ring-blurple-300"
            :class="isSelectedNode ? 'bg-carbon-500 text-white' : 'text-black hover:bg-blurple-50'"
            aria-label="Les lovkommentar"
          >
            <KsIcon :icon="faArrowRight" :scale="0.8" />
          </LawCommentaryLink>
          <LawCommentaryLink
            :law="nodeId"
            class="flex-none flex xl:hidden items-center justify-center rounded-lg h-8 w-8 focus:outline-none focus:ring-blurple-300"
            :class="isSelectedNode && showComment ? 'text-white' : 'text-black hover:bg-blurple-50'"
            aria-label="Les lovkommentar"
            @click="showComment = true"
          >
            <KsIcon :icon="faArrowRight" :scale="0.8" />
          </LawCommentaryLink>
        </template>
      </div>
      <p v-if="lawDescription" class="text-small">
        {{ lawDescription }}
      </p>
      <!-- TODO: Internal links should be enriched with click handlers, so we can do client side nav -->
      <p v-if="handleBodyText(node)" class="pr-xl text-primary" v-html="node.bodytext" />
      <p v-else-if="node.nodeType === 'subsection'" class="pr-xl text-primary" v-html="node.bodytext" />
      <div v-if="node.nodeType === 'note'" class="flex flex-col gap-xs pr-xl pb-2xs">
        <p class="font-bold">
          Kilder
        </p>
        <!-- TODO: Internal links should be enriched with click handlers, so we can do client side nav -->
        <p class="notes" v-html="node.bodytext" />
      </div>
    </div>
  </div>
  <LawCommentaryText
    v-for="child in node.children"
    :key="child.id"
    v-model:show-comment="showComment"
    :node="child"
    :variant="(child.nodeType && NODE_TYPE_MAPPING[child.nodeType]) || 'title'"
    :comments="comments"
    :search-result="searchResult"
  />
</template>

<style scoped lang='postcss'>
:deep(.highlight) {
  @apply bg-blurple-50 not-italic;
}
</style>
