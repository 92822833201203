<script setup lang="ts">
import { KsIcon } from '@aschehoug/kloss'
import { faAngleLeft, faAngleRight, faListUl, faSearch } from '@fortawesome/pro-light-svg-icons'

const showToc = defineModel('show-toc', {
  default: true,
})

const searchMode = defineModel('search-mode', {
  default: false,
})
</script>

<template>
  <div
    class="
      flex items-center justify-between
      sticky top-0 bg-white rounded-tl-4xl
      py-m mb-1 transition-all ease-in-out duration-300
      z-[9]
    "
    :class="showToc ? 'pl-l pr-m' : 'self-center'"
  >
    <div v-if="showToc" class="flex gap-2xs">
      <Button
        size="small"
        :class="{ 'bg-surface-primary': !searchMode }"
        class="flex items-center px-xs gap-2xs rounded-lg hover:bg-blurple-50 focus:outline-none focus:ring-blurple-300"
        aria-label="Innhold"
        @click="searchMode = false"
      >
        <KsIcon :icon="faListUl" class="size-5" />
        Innhold
      </Button>
      <Button
        size="small"
        :class="{ 'bg-surface-primary': searchMode }"
        class="flex items-center px-xs gap-2xs rounded-lg hover:bg-blurple-50 focus:outline-none focus:ring-blurple-300"
        aria-label="Søk"
        @click="searchMode = true"
      >
        <KsIcon :icon="faSearch" class="size-5" />
        Søk
      </Button>
    </div>
    <Button
      size="small"
      class="flex items-center justify-center rounded-lg border !p-0 !h-8 !w-8 focus:outline-none focus:ring-blurple-300"
      :class="showToc ? 'bg-carbon-500 border-transparent text-inverse' : 'border-carbon-500 text-black'"
      :aria-label="showToc ? 'Skjul innhold' : 'Vis innhold'"
      @click="showToc = !showToc"
    >
      <KsIcon :icon="showToc ? faAngleLeft : faAngleRight" />
    </Button>
  </div>
</template>
