<script setup lang="ts">
import type { SolrDoc } from '~/utils/solr/doc'
import { KsIcon } from '@aschehoug/kloss'
import { faAngleDown, faAngleRight } from '@fortawesome/pro-light-svg-icons'

const props = defineProps<{
  node: SolrDoc
  partNumber: number
}>()

const route = useRoute()

const showChildren = ref(false)

const tocRef = useTemplateRef<HTMLElement>('toc')

const params = useLawRouteParams()

const hasChildren = computed(() => props.node.children && props.node.children.length > 0)

function hasSelectedChild(node: SolrDoc) {
  if (!node.children) {
    return false
  }

  for (const child of node.children) {
    if (params.value.nodeId === child.id.toLocaleLowerCase()) {
      return true
    }

    if (hasSelectedChild(child)) {
      return true
    }
  }

  return false
}

const isChildSelected = computed(() => hasSelectedChild(props.node))

const isNodeOrChildSelected = computed(
  () => isChildSelected.value || params.value.nodeId === props.node.id.toLocaleLowerCase(),
)

watch([tocRef, isNodeOrChildSelected], ([el, isSelected]) => {
  showChildren.value = isSelected

  if (el && isSelected) {
    const containerEl = document.getElementById('law-toc-container')

    if (!containerEl) {
      return
    }

    const pageScrollY = window.scrollY

    const elementRect = el.getBoundingClientRect()
    const containerRect = containerEl.getBoundingClientRect()

    const relativeTop = elementRect.top - containerRect.top
    const targetScrollTop = containerEl.scrollTop + relativeTop - 100

    containerEl.scrollTo({ top: targetScrollTop, behavior: 'smooth' })

    requestAnimationFrame(() => {
      window.scrollTo({ top: pageScrollY, behavior: 'smooth' })
    })
  }
})
</script>

<template>
  <li ref="toc" class="flex flex-col gap-3xs scroll-mt-4xl">
    <div class="flex items-start gap-3xs text-center">
      <button
        v-if="hasChildren"
        :id="node.id"
        class="flex items-center w-8 h-8 px-2xs mt-0.5 rounded-lg hover:bg-blurple-50 focus:outline-none focus:ring-blurple-300"
        :class="{ 'bg-carbon-50': showChildren }"
        aria-label="Vis eller skjul underpunkter"
        @click="showChildren = !showChildren"
      >
        <KsIcon :icon="showChildren ? faAngleDown : faAngleRight" />
      </button>

      <button
        class="
        flex w-full items-center text-left p-2xs
        text-small rounded-lg
        hover:bg-blurple-50 focus:outline-none focus:ring-blurple-300
        "
        :class="{
          'bg-carbon-50': isNodeOrChildSelected && !isChildSelected,
          'ml-1': !hasChildren,
        }"
        :aria-label="node.title || `Ledd ${partNumber}`"
        @click="navigateTo({ params: { lovId: node.id.split('/') }, query: { lcq: route.query?.lcq } })"
      >
        {{ node.title || `Ledd ${partNumber}` }}
      </button>
    </div>
    <ul
      v-if="showChildren && hasChildren"
      class="flex flex-col gap-3xs pl-l"
      :aria-expanded="showChildren"
      :aria-label="node.title || undefined"
    >
      <LawCommentaryTableOfContent
        v-for="(child, i) in node.children"
        :key="child.id"
        :node="child"
        :part-number="i + 1"
      />
    </ul>
  </li>
</template>
