<script setup lang="ts">
import type { SolrDoc } from '~/utils/solr/doc'
import { KsIcon } from '@aschehoug/kloss'
import { faBooks, faPenNib } from '@fortawesome/pro-light-svg-icons'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'

const { comment, searchResult } = defineProps<{
  comment: SolrDoc | undefined
  searchResult: { id: string, highlights: string[] } | null
}>()

const showComment = defineModel('show-comment', {
  default: false,
})

const { loggedIn } = useUserSession()

const commentRef = useTemplateRef<HTMLElement>('commentText')

watch(() => commentRef, (el) => {
  if (el.value && searchResult) {
    // Apply highlights to the text
    el.value.innerHTML = applyHighlightsToWords(el.value, searchResult.highlights, searchResult.id)

    nextTick(() => {
      // Scroll to the highlighted text
      const containerEl = document.getElementById('law-commentary-container')
      const element = document.getElementById(searchResult.id)

      if (containerEl && element) {
        const pageScrollY = window.scrollY

        const elementRect = element.getBoundingClientRect()
        const containerRect = containerEl.getBoundingClientRect()

        const relativeTop = elementRect.top - containerRect.top
        const targetScrollTop = containerEl.scrollTop + relativeTop - 100

        containerEl.scrollTo({ top: targetScrollTop, behavior: 'smooth' })

        // Restore the page scroll position after the scroll animation
        requestAnimationFrame(() => {
          window.scrollTo({ top: pageScrollY, behavior: 'smooth' })
        })
      }
    })
  }
}, { deep: true })

// TODO: Implement logic to registration button

// TODO: Check whether the illustration should be stored locally or fetched from the API (Crystallize), consistent with the approach used in the other components
</script>

<template>
  <div v-if="!loggedIn" class="flex flex-col gap-m h-full">
    <p class="text-small text-primary font-bold no-underline">
      Lovkommentar
    </p>
    <div class="flex flex-col justify-center pt-3xl pb-xl gap-m h-[650px]">
      <div class="flex flex-col items-center gap-xl">
        <div class="w-[224px] mx-auto">
          <IllustrationJudge />
        </div>
        <p>
          Du må logge inn for å se dette innholdet
        </p>
      </div>
      <div class="flex flex-col gap-xs items-center w-[240px] mx-auto">
        <Button variant="secondary" to="/logg-inn" size="medium" :icon="faBooks" icon-position="left" class="w-full">
          Logg inn
        </Button>
        <Button variant="primary" size="medium" class="!bg-blurple-500 !text-inverse w-full">
          Registrer ny bruker
        </Button>
      </div>
    </div>
  </div>
  <div v-else-if="comment?.bodytext" ref="commentText" :key="comment.id">
    <div class="sticky top-0 z-[9] bg-white px-l py-m flex flex-col xl:flex-row justify-between xl:items-center gap-m">
      <Button size="small" variant="tertiary" color="blurple" :icon="faArrowLeft" class="flex xl:hidden !bg-transparent !px-0 !text-blurple-300" @click="showComment = false">
        Tilbake til lovteksten
      </Button>
      <p class="text-small text-primary font-bold no-underline">
        Lovkommentar til {{ comment.title?.match(/§ \d+/)?.[0] ?? comment.title }}
      </p>
      <Chip variant="filled" color="moss" size="small" class="text-caption hover:outline-transparent shrink-0">
        Ajourført {{ formatDate(new Date(comment.timestamp ?? ''), { year: 'numeric', month: 'long', day: 'numeric' }) }}
      </Chip>
    </div>
    <div class=" flex flex-col gap-m px-l">
      <div v-if="!!comment.authors.length" class="flex items-center gap-3xs">
        <KsIcon :icon="faPenNib" class="text-secondary" :scale="0.8" />
        <template v-for="(author, index) in comment.authors" :key="author">
          <NuxtLink to="/forfattere/" class="text-caption underline text-secondary">
            {{ author }}
          </NuxtLink>
          <span v-if="index < comment.authors.length - 2" class="text-caption text-secondary -ml-3xs">,</span>
          <span v-else-if="index === comment.authors.length - 2" class="text-caption text-secondary">og</span>
        </template>
      </div>
      <h4>{{ comment.title }}</h4>
      <div class="bodytext" v-html="comment.bodytext" />
    </div>
  </div>
  <div v-else class="px-l py-m text-caption italic">
    Ingen lovkommentar tilgjengelig
  </div>
</template>

<style scoped lang="postcss">
:deep(.bodytext) {
  p {
    @apply text-lg mb-s;
  }

  h2 {
    @apply text-xl mb-3;
  }
}

:deep(.highlight) {
  @apply bg-blurple-50 not-italic;
}
</style>
