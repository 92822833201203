import type { SolrDoc } from './solr/doc'

export function filterOutNodesByTypes(nodes: SolrDoc[] | null | undefined, excludedTypes: string[] = []): SolrDoc[] {
  if (!nodes) {
    return []
  }
  return nodes
    .flatMap((node) => {
      // Base case: if node has no children, exclude if it matches an excluded type
      if ((!node.children || node.children.length === 0) && node.nodeType) {
        return excludedTypes.includes(node.nodeType) ? null : node
      }

      const filteredChildren = filterOutNodesByTypes(node.children, excludedTypes)

      // If the node's type is in the excludedTypes array, return only the filtered children
      if (node.nodeType && excludedTypes.includes(node.nodeType)) {
        return filteredChildren.length ? filteredChildren : null
      }

      // If not excluded, return the node with filtered children
      return {
        ...node,
        children: filteredChildren,
      }
    })
    .filter(node => node !== null) // Remove any null values from the array
}
