<script lang="ts">
import { SolrParamsKey } from '#shared/solr'
import { NODE_TYPE_MAPPING } from '~/utils/solr/doc-utils'

export const LAW_ROUTE_NAME = 'law-by-id'
</script>

<script setup lang="ts">
definePageMeta({
  name: LAW_ROUTE_NAME,
  key: LAW_ROUTE_NAME,
  layout: 'law-comment',
  colorMode: 'light',
  theme: 'carbon',
})

const { loggedIn } = useUserSession()

const route = useRoute()

const params = useLawRouteParams()

const showToc = ref<boolean>(true)

const searchMode = ref<boolean>(false)

const showComment = defineModel('showComment', {
  default: false,
})

const { data: lawData, suspense } = useSolrQuery(
  'content',
  computed(() => ({ [SolrParamsKey.LawId]: params.value.lawId })),
)

const laws = computed(() => lawData.value?.response?.docs ?? [])

const law = computed(() => laws.value?.[0])

await suspense()

const commentsQuery = useSolrQuery(
  'content',
  computed(() => ({ [SolrParamsKey.CommentIdsByLawId]: params.value.lawId })),
)

const comments = computed(() => commentsQuery.data.value?.response?.docs[0]?.commentDocs?.docs ?? [])

const noComments = computed(() => !comments.value.length)

const lawCommentId = computed(() => comments.value.find(comment => comment.lawId === params.value.lawId)?.ezMainNodeId)

const tocTree = computed(() => filterOutNodesByTypes(laws.value, ['law', 'note']))

const commentNodeIds = computed(() => new Set(comments.value.map(it =>
  `${params.value.lawId}${it.lawFragmentId ? `/${it.lawFragmentId}` : ''}`,
)))

// Restore search mode if query is present
watch(() => route.query.lcq, (value) => {
  if (value) {
    searchMode.value = true
  }
})

const commentId = computed(() => {
  let fragmentId = params.value.lawFragmentId

  while (fragmentId) {
    const found = comments.value.find(c => c.lawFragmentId === fragmentId)
    if (found) {
      return found.ezMainNodeId
    }

    const lastSlashIndex = fragmentId.lastIndexOf('/')
    if (lastSlashIndex === -1) {
      break
    }
    fragmentId = fragmentId.substring(0, lastSlashIndex)
  }

  // Fallback: match by lawId if no lawFragmentId is found, relevant for comments for the whole law
  return comments.value.find(c => c.lawId === params.value.lawId)?.ezMainNodeId
})

const selectedCommentQuery = useSolrQuery(
  'content',
  computed(() => ({ [SolrParamsKey.CommentByEzMainNodeId]: commentId.value?.toString() })),
)

const selectedComment = computed(() => selectedCommentQuery.data.value?.response?.docs[0])

const title = computed(() => law.value?.title ?? 'Lov')

const breadcrumbOverrides = computed(() => {
  const segments = route.path.split('/')

  const path = segments.map((_, i) => {
    if (i < 2) {
      return undefined
    }

    if (i === 2) {
      return {
        label: title.value,
        to: `/lover-og-lovkommentarer/${params.value.lawId}`,
      }
    }

    return false
  })

  return path
})

const selectedSearchResultLaw = ref<{ id: string, highlights: string[] } | null>(null)
const selectedSearchResultComment = ref<{ id: string, highlights: string[] } | null>(null)

function showSearchResult(id: string, highlights: string[], isComment: boolean) {
  if (!isComment) {
    selectedSearchResultLaw.value = { id, highlights }
  }
  else {
    selectedSearchResultComment.value = { id, highlights }
  }
}

useHead({
  htmlAttrs: {
    class: 'bg-carbon-50',
  },
  title,
})
</script>

<template>
  <Breadcrumbs :overrides="breadcrumbOverrides" />
  <ContentContainer>
    <LawCommentaryHeader v-if="law" :law="law" />
  </ContentContainer>
  <section class="pb-4xl flex gap-3xs w-full max-w-9xl mx-auto px-s">
    <div
      id="law-toc-container"
      class="
        flex flex-col rounded-tl-4xl bg-white pb-xl gap-m max-h-screen
        overflow-y-auto no-scrollbar transition-all ease-in-out duration-300
      "
      :class="{
        'w-24': !showToc,
        'w-96': showToc,
        'md:max-xl:hidden': showComment,
      }"
    >
      <LawCommentaryTableOfContentToggle v-model:search-mode="searchMode" v-model:show-toc="showToc" />
      <div v-show="showToc" class="flex-1 flex flex-col gap-s animate-fade" :class="showToc ? 'px-m' : 'px-s'">
        <LawCommentarySearch
          :search-mode="searchMode"
          :law-comment-id="lawCommentId"
          @show-search-result="showSearchResult"
        />
        <ul v-if="!searchMode">
          <LawCommentaryTableOfContent
            v-for="node in tocTree"
            :key="node.id"
            :node="node"
            :part-number="0"
          />
        </ul>
      </div>
    </div>
    <div
      id="law-text-container"
      class="
      flex-1 bg-white px-m pb-m
      w-full rounded-tr-4xl xl:rounded-none
      max-h-screen overflow-y-auto no-scrollbar
      transition-all ease-in-out duration-300
      "
      :class="{
        'max-w-full': !loggedIn || noComments,
        'md:max-xl:hidden': showComment,
      }"
    >
      <div class="sticky top-0 z-[9] flex justify-between items-center pl-l text-caption py-m bg-white">
        <p class="text-small text-primary font-bold">
          Lov
        </p>
        <span :aria-label="`Kilde: ${law?.source}`" class="text-caption italic">Kilde: {{ law?.source }}</span>
      </div>
      <LawCommentaryText
        v-for="node in laws"
        :key="node.id"
        v-model:show-comment="showComment"
        :node="node"
        :variant="(node.nodeType && NODE_TYPE_MAPPING[node.nodeType]) || 'title'"
        :search-result="selectedSearchResultLaw"
        :comments="commentNodeIds"
      />
    </div>
    <div
      id="law-commentary-container"
      class="
        hidden xl:flex flex-1 rounded-tr-4xl
        overflow-y-auto no-scrollbar w-full max-h-screen
        transition-all ease-in-out duration-300
      "
      :class="{
        'bg-white': loggedIn,
        'bg-section-alternate py-m px-l w-full max-w-md': !loggedIn,
        'flex-none py-m px-l w-full max-w-xs': loggedIn && noComments,
        'md:max-xl:flex md:max-xl:flex-col md:max-xl:w-full md:max-xl:rounded-4xl': showComment,
        'md:max-xl:hidden': !showComment,
      }"
    >
      <LawCommentaryComment
        v-model:show-comment="showComment"
        :comment="selectedComment"
        :search-result="selectedSearchResultComment"
      />
    </div>
  </section>
</template>
