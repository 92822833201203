<script setup lang="ts">
import type { SolrDoc } from '~/utils/solr/doc'
import { SolrParamsKey } from '#shared/solr'
import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import SearchInput from '~/components/form/SearchInput.vue'

const props = defineProps<{ lawCommentId: number | null | undefined, searchMode: boolean }>()

const emit = defineEmits<{
  showSearchResult: [string, string[], boolean]
}>()

const lawFilterSelected = ref(false)
const commentFilterSelected = ref(false)
const searchForm = ref<HTMLFormElement | null>(null)

const params = useLawRouteParams()

const KEY = 'lcq'

const { query, handleSearch, searchResult } = useSearch(KEY)

// Perform search on page load if query is present
onMounted(() => {
  if (query[KEY]) {
    submitSearch()
  }
})

function handleFilter(filterType: string) {
  if (filterType === 'law') {
    lawFilterSelected.value = !lawFilterSelected.value
    if (lawFilterSelected.value) {
      commentFilterSelected.value = false
    }
  }
  else if (filterType === 'comment') {
    commentFilterSelected.value = !commentFilterSelected.value
    if (commentFilterSelected.value) {
      lawFilterSelected.value = false
    }
  }
  submitSearch()
}

const handleSearchAsYouType = useDebounceFn((value: string) => {
  if (value.length > 3) {
    submitSearch()
  }
}, 500)

function submitSearch() {
  if (searchForm.value) {
    searchForm.value.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }))
  }
}

function getSearchQueryOptions() {
  const queryOptions: Record<string, string | number | null | undefined> = {}
  if (!lawFilterSelected.value) {
    queryOptions.commentId = props.lawCommentId
  }
  if (!commentFilterSelected.value) {
    queryOptions.lawId = params.value.lawId
  }
  return queryOptions
}

function handleShowSearchResult(nodeId: string | undefined, highlights: string[] | undefined | null, lawOrComment: boolean) {
  if (!nodeId || !highlights) {
    return
  }

  emit('showSearchResult', nodeId, highlights, lawOrComment)
}

function isCommentary(doc: SolrDoc | undefined | null): boolean {
  return !!doc?.lawFragmentId || !!doc?.lawId
}

function activeHighlight(nodeId: string | undefined): boolean {
  return `${params.value.lawId}/${params.value.lawFragmentId}` === nodeId?.toLocaleLowerCase()
}

function handleLink(doc: SolrDoc | undefined): string {
  if (!doc?.id) {
    return ''
  }

  if (isCommentary(doc)) {
    return `${params.value.lawId}/${doc?.lawFragmentId}`
  }

  return doc.id
}
</script>

<template>
  <div v-show="searchMode" class="flex flex-col gap-m">
    <form
      ref="searchForm"
      @submit.prevent="handleSearch($event, { [SolrParamsKey.SearchCommentAndLaw]: getSearchQueryOptions() })"
    >
      <SearchInput
        v-model="query[KEY]"
        shape="square"
        placeholder="Søk i lov og lovkommentarer"
        size="medium"
        :name="KEY"
        @update:model-value="handleSearchAsYouType"
      />
    </form>
    <div class="flex items-center gap-1 w-full">
      <p class="text-caption flex-1">
        Viser {{ searchResult?.response.numFound }} treff i
      </p>
      <div v-if="lawCommentId" class="flex gap-2xs flex-wrap">
        <Chip
          :icon-left="lawFilterSelected ? faXmark : undefined"
          :variant="lawFilterSelected ? 'filled' : 'outlined'"
          size="small"
          :color="lawFilterSelected ? 'blurple' : 'carbon'"
          :class="lawFilterSelected ? 'bg-surface-invert text-inverse' : 'border border-carbon-500 text-primary'"
          @click="handleFilter('law')"
        >
          <span class="text-caption">
            Lov
          </span>
        </Chip>
        <Chip
          :icon-left="commentFilterSelected ? faXmark : undefined"
          :variant="commentFilterSelected ? 'filled' : 'outlined'"
          size="small"
          :color="commentFilterSelected ? 'blurple' : 'carbon'"
          :class="commentFilterSelected ? 'bg-surface-invert text-inverse' : 'border border-carbon-500 text-primary'"
          @click="handleFilter('comment')"
        >
          <span class="text-caption">
            Lovkommentar
          </span>
        </Chip>
      </div>
    </div>
    <div>
      <h2 class="sr-only">
        Søkeresultat
      </h2>
      <template v-for="(highlight, key, i) in searchResult?.highlighting" :key="key">
        <div
          v-if="highlight.bodytext || highlight.title"
          class="px-s pt-xs pb-s border-b border-b-border-secondary"
          :class="{ 'active-highlight': activeHighlight(handleLink(searchResult?.response.docs[i])) }"
        >
          <p v-if="isCommentary(searchResult?.response.docs[i])" class="text-caption">
            Lovkommentar
          </p>
          <p v-else class="text-caption">
            Lov
          </p>
          <LawCommentaryLink
            class="flex flex-col gap-2xs my-xs search-result no-underline"
            :law="handleLink(searchResult?.response.docs[i])"
            aria-label="Vis søkeresultat"
            @click="handleShowSearchResult(
              searchResult?.response.docs[i]?.id,
              highlight.bodytext || highlight.title,
              isCommentary(searchResult?.response.docs[i]),
            )"
          >
            <template v-if="highlight.title">
              <p
                v-for="text in highlight.title"
                :key="text"
                class="text-small"
                v-html="text"
              />
            </template>
            <template v-else>
              <p class="text-small">
                {{ searchResult?.response.docs[i]?.title
                  || searchResult?.response.docs[i]?.paragraphTitle
                  || searchResult?.response.docs[i]?.chapterTitle
                }}
              </p>
            </template>
            <template v-if="highlight.bodytext">
              <span
                v-for="text in highlight.bodytext"
                :key="text"
                class="text-caption search-result-body"
                v-html="text"
              />
            </template>
          </LawCommentaryLink>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang='postcss'>
:deep(.search-result-body a),
:deep(.search-result-body p),
:deep(.search-result-body h1),
:deep(.search-result-body h2),
:deep(.search-result-body h3),
:deep(.search-result-body h4) {
  @apply text-xs;
}

:deep(.search-result .highlight) {
  @apply bg-blurple-50 not-italic;
}
.active-highlight {
  @apply bg-carbon-50;
}
</style>
