<script setup lang="ts">
import type { Law } from '~/utils/solr/law'
import { LAW_ROUTE_NAME } from '../../pages/lover-og-lovkommentarer/[...lovId].vue'

const props = defineProps<{
  law: Law | string | string[]
}>()

const query = useRoute().query

const lovId = computed(
  () => Array.isArray(props.law)
    ? props.law
    : typeof props.law === 'string'
      ? props.law.split('/').filter(Boolean)
      : [props.law.id],
)
</script>

<template>
  <NuxtLink :to="{ name: LAW_ROUTE_NAME, params: { lovId }, query }">
    <slot />
  </NuxtLink>
</template>
