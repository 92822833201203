import type { SolrDoc } from '~/utils/solr/doc'
import { object, optional, parse, pipe, string, transform } from 'valibot'

const MISC_INFORMATION_KEY = 'miscInformation'

const LINK_REGEX = /<a[^>]*href=['"]([^'"]*)['"][^>]*>(.*?)<\/a>/

const metadataSchema = object({
  [MISC_INFORMATION_KEY]: pipe(
    optional(string()),
    transform((value) => {
      if (!value) {
        return
      }

      const isRepealed = value.includes('opphevet')
      const lawIdMatch = value.match(LINK_REGEX)
      const lawId = lawIdMatch ? lawIdMatch[1] : null

      const parts = lawId?.split('-')
      const lawDate = parts && parts.length >= 4 ? formatDate(new Date(`${parts[1]}-${parts[2]}-${parts[3]}`), { day: '2-digit', month: 'long', year: 'numeric' }) : null
      const lawNumber = parts && parts.length >= 5 ? parts[4] : null

      return {
        isRepealed,
        lawId,
        lawDate,
        lawNumber,
      }
    }),
  ),
})

export function useLawMetadata(law: SolrDoc) {
  return computed(() => parse(metadataSchema, law)[MISC_INFORMATION_KEY])
}
