<script>
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="224" height="208" viewBox="0 0 224 208" fill="none">
    <path d="M148.386 86.6625C157.086 86.6625 164.138 79.5945 164.138 70.8756C164.138 62.1567 157.086 55.0887 148.386 55.0887C139.687 55.0887 132.635 62.1567 132.635 70.8756C132.635 79.5945 139.687 86.6625 148.386 86.6625Z" fill="#F8F8F8" />
    <path d="M142.767 43.1304C149.947 43.1304 155.767 37.297 155.767 30.1011C155.767 22.9052 149.947 17.0718 142.767 17.0718C135.587 17.0718 129.767 22.9052 129.767 30.1011C129.767 37.297 135.587 43.1304 142.767 43.1304Z" fill="#F8F8F8" />
    <path d="M124.012 45.4001C132.442 45.4001 139.276 38.5509 139.276 30.1019C139.276 21.653 132.442 14.8038 124.012 14.8038C115.582 14.8038 108.748 21.653 108.748 30.1019C108.748 38.5509 115.582 45.4001 124.012 45.4001Z" fill="#F8F8F8" />
    <path d="M145.875 64.9702C154.574 64.9702 161.626 57.9022 161.626 49.1833C161.626 40.4644 154.574 33.3964 145.875 33.3964C137.175 33.3964 130.123 40.4644 130.123 49.1833C130.123 57.9022 137.175 64.9702 145.875 64.9702Z" fill="#F8F8F8" />
    <path d="M79.1344 64.9702C87.8338 64.9702 94.886 57.9022 94.886 49.1833C94.886 40.4644 87.8338 33.3964 79.1344 33.3964C70.435 33.3964 63.3828 40.4644 63.3828 49.1833C63.3828 57.9022 70.435 64.9702 79.1344 64.9702Z" fill="#F8F8F8" />
    <path d="M76.6119 86.6625C85.3113 86.6625 92.3635 79.5945 92.3635 70.8756C92.3635 62.1567 85.3113 55.0887 76.6119 55.0887C67.9126 55.0887 60.8604 62.1567 60.8604 70.8756C60.8604 79.5945 67.9126 86.6625 76.6119 86.6625Z" fill="#F8F8F8" />
    <path d="M63.9824 61.9747C65.4382 55.9123 74.1001 54.2417 78.0744 59.0639C80.0907 61.3837 80.4255 64.9803 78.9333 67.6285C77.6814 70.1891 74.3913 72.0129 71.7054 70.7727C76.9899 71.1886 79.6831 64.1195 76.4003 60.4718C73.0665 56.6564 66.6683 57.6413 63.9824 61.9674V61.9747Z" fill="white" />
    <path d="M130.675 72.0098H89.9131V144.692H130.675V72.0098Z" fill="#D1DBFF" />
    <path d="M201.581 161.808L207.157 148.232C207.659 147.014 207.463 145.62 206.647 144.584L185.502 117.738C185.218 117.38 184.876 117.081 184.483 116.848L135.933 88.4181C133.611 87.0611 130.67 88.6077 130.466 91.2997L129.44 105.08C129.396 105.671 129.207 106.24 128.894 106.744L115.413 128.316C113.987 130.599 110.667 130.599 109.241 128.316L95.7602 106.744C95.4472 106.24 95.258 105.671 95.2143 105.08L94.188 91.2997C93.9915 88.6077 91.0435 87.0611 88.7215 88.4181L40.1711 116.848C39.778 117.081 39.4359 117.38 39.1521 117.738L18.0068 144.584C17.1915 145.62 16.995 147.014 17.4973 148.232L23.0729 161.808C23.5315 162.924 23.4077 164.194 22.7454 165.201L6.87002 189.384C5.71268 191.15 6.28771 193.535 8.12928 194.571L24.2448 203.654C24.7907 203.96 25.4022 204.121 26.0282 204.121H198.597C199.223 204.121 199.834 203.96 200.38 203.654L216.496 194.571C218.337 193.535 218.912 191.157 217.755 189.384L201.88 165.201C201.217 164.194 201.094 162.924 201.552 161.808H201.581Z" fill="#00173B" />
    <path d="M124.348 82.14C125.011 105.448 91.3311 106.761 90.6396 82.6287L92.3065 71.0439H123.169L124.348 82.14Z" fill="#A3B5FE" />
    <path d="M75.0252 107.754C86.6149 107.754 96.0103 98.3378 96.0103 86.722C96.0103 75.1062 86.6149 65.6898 75.0252 65.6898C63.4354 65.6898 54.04 75.1062 54.04 86.722C54.04 98.3378 63.4354 107.754 75.0252 107.754Z" fill="#F8F8F8" />
    <path d="M84.8342 203.126C84.8342 203.126 89.944 191.774 97.5432 189.148L91.1887 184.72C91.1887 184.72 79.3095 187.339 76.5508 204.125L84.8342 203.126Z" fill="#F8F8F8" />
    <path d="M91.1894 184.713C93.0237 183.239 94.8944 181.817 96.7724 180.416L96.8524 181.51C93.8899 180.445 89.5735 178.746 86.5819 177.578C83.5684 176.338 79.3685 174.667 76.4205 173.405C75.6417 173.084 75.2632 172.209 75.5252 171.421L76.6898 167.277L77.5051 169.298C72.9412 166.869 68.4064 164.374 63.8935 161.842C63.4931 161.624 63.202 161.266 63.0637 160.865C62.0155 157.808 60.9892 154.751 59.9847 151.68C57.0149 142.444 54.147 133.172 51.876 123.732C57.3788 135.441 62.0301 147.515 66.4629 159.661L65.6331 158.684C70.1897 161.171 74.7245 163.703 79.2229 166.293C79.9289 166.701 80.2347 167.54 79.9945 168.284L78.6624 172.377L77.7671 170.392C79.4121 171.195 81.0645 171.976 82.7022 172.807C87.2734 175.069 92.8927 177.943 97.3547 180.372C97.8205 180.54 97.8861 181.24 97.4348 181.467C95.3675 182.583 93.293 183.677 91.1822 184.72L91.1894 184.713Z" fill="#063BA1" />
    <path d="M72.7584 167.582C63.3249 170.405 53.2946 172.615 43.6354 174.629L43.2933 172.469C50.6378 171.667 57.9968 171.018 65.3921 170.93C67.8306 171.025 70.4437 170.726 72.7584 171.557C72.1761 171.36 71.5501 171.441 70.946 171.455C64.8972 171.922 56.9195 173.089 50.8561 173.783C50.8561 173.783 43.5481 174.643 43.5408 174.643C42.1724 174.789 41.8521 172.768 43.206 172.484C52.8942 170.565 62.9683 168.676 72.7584 167.582Z" fill="#00173B" />
    <path d="M133.499 184.716C131.395 183.673 129.321 182.578 127.246 181.462C126.802 181.229 126.861 180.543 127.327 180.368C131.774 177.946 137.408 175.064 141.979 172.803C143.61 171.978 145.269 171.19 146.914 170.388L146.019 172.372L144.687 168.28C144.432 167.499 144.774 166.675 145.451 166.288C149.949 163.698 154.484 161.167 159.041 158.679L158.211 159.657C162.644 147.51 167.295 135.436 172.798 123.727C171.284 130.031 169.493 136.239 167.601 142.433C166.647 145.518 165.694 148.604 164.689 151.676C163.685 154.747 162.658 157.804 161.61 160.86C161.465 161.283 161.174 161.619 160.81 161.823C156.289 164.362 151.755 166.857 147.176 169.294L147.991 167.273L149.156 171.417C149.425 172.219 149.025 173.087 148.261 173.401C145.327 174.663 141.091 176.355 138.099 177.574C135.108 178.734 130.813 180.433 127.829 181.506L127.909 180.412C129.787 181.82 131.658 183.242 133.492 184.709L133.499 184.716Z" fill="#063BA1" />
    <path d="M151.924 167.582C161.707 168.676 171.803 170.565 181.476 172.484C182.823 172.761 182.517 174.782 181.141 174.643L173.826 173.783C167.748 173.097 159.792 171.93 153.736 171.455C153.132 171.441 152.506 171.36 151.924 171.557C154.239 170.726 156.852 171.025 159.29 170.93C166.685 171.01 174.044 171.667 181.389 172.469L181.047 174.629C171.38 172.608 161.365 170.405 151.924 167.582Z" fill="#00173B" />
    <path d="M94.2705 92.1713L95.2968 105.974L102.474 117.464L112.337 107.294C101.775 107.294 94.2705 92.164 94.2705 92.164V92.1713Z" fill="#CFFDFF" />
    <path d="M112.34 107.292L122.203 117.462L129.38 105.972L130.406 92.1694C130.406 92.1694 122.902 107.3 112.34 107.3V107.292Z" fill="#CFFDFF" />
    <path d="M82.2394 43.1304C89.4192 43.1304 95.2396 37.297 95.2396 30.1011C95.2396 22.9052 89.4192 17.0718 82.2394 17.0718C75.0596 17.0718 69.2393 22.9052 69.2393 30.1011C69.2393 37.297 75.0596 43.1304 82.2394 43.1304Z" fill="#CFFDFF" />
    <path d="M82.2394 43.1304C89.4192 43.1304 95.2396 37.297 95.2396 30.1011C95.2396 22.9052 89.4192 17.0718 82.2394 17.0718C75.0596 17.0718 69.2393 22.9052 69.2393 30.1011C69.2393 37.297 75.0596 43.1304 82.2394 43.1304Z" fill="#F8F8F8" />
    <path d="M101.869 43.2923C113.615 43.2923 123.138 33.7485 123.138 21.9755C123.138 10.2026 113.615 0.658813 101.869 0.658813C90.1221 0.658813 80.5996 10.2026 80.5996 21.9755C80.5996 33.7485 90.1221 43.2923 101.869 43.2923Z" fill="#CFFDFF" />
    <path d="M100.122 45.4001C108.552 45.4001 115.386 38.5509 115.386 30.1019C115.386 21.653 108.552 14.8038 100.122 14.8038C91.6923 14.8038 84.8584 21.653 84.8584 30.1019C84.8584 38.5509 91.6923 45.4001 100.122 45.4001Z" fill="#F8F8F8" />
    <path d="M25.8112 155.233C16.8435 161.967 7.83224 169.364 2.76611 179.585C1.47046 182.189 0.44413 185.034 0.502361 187.967C0.611545 193.285 4.36019 197.947 8.89496 200.405C13.4224 202.864 18.6414 203.506 23.7367 203.922C29.4579 204.389 35.958 204.323 39.9396 200.026C41.8612 197.954 42.8948 195.168 43.7537 192.432C44.5908 189.747 45.3114 186.851 44.5617 184.144C44.0376 182.277 42.8657 180.686 41.7156 179.154C35.9944 171.524 29.7345 163.491 20.8761 160.559" fill="#00173B" />
    <path d="M198.19 154.402C207.157 161.135 216.169 168.533 221.235 178.753C222.531 181.358 223.557 184.203 223.499 187.135C223.389 192.454 219.641 197.115 215.106 199.574C210.579 202.032 205.36 202.674 200.264 203.09C194.543 203.557 188.043 203.491 184.061 199.194C182.14 197.123 181.106 194.336 180.247 191.6C179.41 188.915 178.69 186.019 179.439 183.313C179.963 181.445 181.135 179.855 182.285 178.323C188.007 170.692 194.266 162.66 203.125 159.727" fill="#00173B" />
    <path d="M117.439 163.757C119.194 162.276 120.715 160.525 121.938 158.585C122.404 157.848 122.833 157.045 122.899 156.17C122.979 155.083 122.484 154.04 121.996 153.069C119.725 148.51 117.338 143.644 117.898 138.574C118.378 134.204 121.006 130.381 123.867 127.04C129.472 120.481 136.299 114.973 143.884 110.881C148.499 108.393 153.507 106.394 158.74 106.088C163.974 105.781 169.491 107.343 173.145 111.114C177.09 115.185 178.379 121.335 177.512 126.938C176.646 132.548 173.858 137.684 170.561 142.302C167.569 146.504 164.126 150.37 160.523 154.054C156.84 157.819 152.822 161.503 147.873 163.297C144.211 164.625 140.259 164.844 136.372 165.048C132.696 165.245 129.02 165.435 125.344 165.632C122.367 165.792 118.997 165.807 116.894 163.691" fill="#00173B" />
    <path d="M16.0092 166.431C14.83 155.896 13.811 144.523 19.1173 135.353C21.2864 131.596 24.3945 128.474 27.6482 125.614C32.8599 121.025 38.552 116.976 44.5935 113.555C49.878 110.564 55.5556 108.01 61.5898 107.376C67.6241 106.741 74.1096 108.244 78.4842 112.46C81.5195 115.386 83.3684 120.128 81.4686 123.892C80.2821 126.234 77.931 127.715 75.6891 129.079C57.0842 140.401 38.472 151.716 19.867 163.038" fill="#00173B" />
    <path d="M207.989 165.598C209.168 155.064 210.187 143.691 204.881 134.52C202.712 130.763 199.604 127.641 196.35 124.781C191.138 120.192 185.446 116.144 179.405 112.722C174.12 109.731 168.442 107.178 162.408 106.543C156.374 105.908 149.888 107.411 145.514 111.628C142.479 114.553 140.63 119.295 142.529 123.06C143.716 125.401 146.067 126.882 148.309 128.246C166.914 139.569 185.526 150.884 204.131 162.206" fill="#00173B" />
    <path d="M56.7021 76.6638C60.7783 64.2035 80.2131 65.3051 81.8435 78.8305C82.4768 84.5573 78.5244 90.9407 72.5338 91.4367C71.4129 91.517 70.2919 91.3565 69.2729 90.9917C78.3278 92.1517 83.0955 80.0853 77.4398 73.7822C71.886 67.2457 60.691 69.1133 56.7021 76.6638Z" fill="white" />
    <path d="M66.165 41.0961C67.6208 35.0337 76.2827 33.3631 80.257 38.1853C82.2733 40.5051 82.6081 44.1017 81.1159 46.7499C79.864 49.3105 76.5739 51.1343 73.888 49.8941C79.1725 50.31 81.8657 43.2409 78.5829 39.5932C75.2491 35.7778 68.851 36.7627 66.165 41.0888V41.0961Z" fill="white" />
    <path d="M118.271 107.295H107.273L86.9717 156.458C102.723 166.693 119.392 167.321 137.153 156.458L118.264 107.295H118.271Z" fill="#F8F8F8" />
    <path d="M106.994 139.788L86.9766 135.834L106.994 107.295H114.884L106.994 139.788Z" fill="white" />
    <path d="M150.706 109.947C162.296 109.947 171.691 100.531 171.691 88.9152C171.691 77.2994 162.296 67.8829 150.706 67.8829C139.116 67.8829 129.721 77.2994 129.721 88.9152C129.721 100.531 139.116 109.947 150.706 109.947Z" fill="#F8F8F8" />
    <path d="M161.549 62.7057C158.863 58.3723 152.458 57.3874 149.132 61.2101C145.849 64.8578 148.542 71.9269 153.826 71.511C151.141 72.7512 147.85 70.9347 146.598 68.3668C145.106 65.7186 145.441 62.122 147.465 59.8021C151.439 54.98 160.101 56.6433 161.557 62.7129L161.549 62.7057Z" fill="white" />
    <path d="M169.392 79.5813C165.403 72.0307 154.208 70.1631 148.654 76.6997C142.998 82.9955 147.773 95.0691 156.821 93.9092C155.795 94.2813 154.681 94.4417 153.56 94.3542C147.562 93.8581 143.617 87.4821 144.25 81.748C145.881 68.2153 165.315 67.121 169.392 79.5813Z" fill="white" />
    <path d="M159.372 41.5441C156.686 37.2107 150.28 36.2259 146.954 40.0486C143.671 43.6962 146.364 50.7653 151.649 50.3495C148.963 51.5897 145.673 49.7731 144.421 47.2052C142.929 44.557 143.263 40.9605 145.287 38.6406C149.261 33.8184 157.923 35.4817 159.379 41.5514L159.372 41.5441Z" fill="white" />
    <path d="M112.336 94.4036C112.336 94.4036 97.9528 96.5776 91.147 77.9674C91.147 77.9674 84.8071 52.6967 92.8503 37.041C92.8503 37.041 98.113 23.7053 112.343 23.7053V94.4036H112.336Z" fill="#D1DBFF" />
    <path d="M88.7869 59.0906C86.676 56.8656 84.1866 54.3049 81.0349 53.7359C78.4727 53.2763 76.1143 54.5019 74.644 56.5738C72.9553 58.9447 72.1837 62.2349 73.0572 65.0654C74.1854 68.7131 77.7157 69.8365 81.2096 69.8511C83.9755 69.8657 86.8871 69.9533 89.6313 69.5885" fill="#D1DBFF" />
    <path d="M112.337 94.4036C112.337 94.4036 126.721 96.5776 133.526 77.9674C133.526 77.9674 139.866 52.6967 131.823 37.041C131.823 37.041 126.56 23.7053 112.33 23.7053V94.4036H112.337Z" fill="#D1DBFF" />
    <path d="M135.889 59.0906C138 56.8656 140.49 54.3049 143.641 53.7359C146.204 53.2763 148.562 54.5019 150.032 56.5738C151.721 58.9447 152.493 62.2349 151.619 65.0654C150.491 68.7131 146.961 69.8365 143.467 69.8511C140.701 69.8657 137.789 69.9533 135.045 69.5885" fill="#D1DBFF" />
    <path d="M147.324 58.3267C147.324 58.3267 141.137 54.752 139.71 63.3312L147.324 58.3267Z" fill="#D1DBFF" />
    <path d="M147.324 58.3267C147.324 58.3267 141.137 54.752 139.71 63.3312" stroke="#00173B" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M144.994 61.0947C144.994 61.0947 145.424 58.038 143.211 57.79L144.994 61.0947Z" fill="#D1DBFF" />
    <path d="M144.994 61.0947C144.994 61.0947 145.424 58.038 143.211 57.79" stroke="#00173B" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M77.3525 58.9557C77.3525 58.9557 83.5396 55.381 84.9663 63.9602L77.3525 58.9557Z" fill="#D1DBFF" />
    <path d="M77.3525 58.9557C77.3525 58.9557 83.5396 55.381 84.9663 63.9602" stroke="#00173B" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M79.682 61.7426C79.682 61.7426 79.2526 58.6859 81.4654 58.4379L79.682 61.7426Z" fill="#D1DBFF" />
    <path d="M79.682 61.7426C79.682 61.7426 79.2526 58.6859 81.4654 58.4379" stroke="#00173B" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M103.003 60.4549C104.35 60.4549 105.441 59.3607 105.441 58.011C105.441 56.6613 104.35 55.5671 103.003 55.5671C101.656 55.5671 100.564 56.6613 100.564 58.011C100.564 59.3607 101.656 60.4549 103.003 60.4549Z" fill="#00173B" />
    <path d="M122.632 60.4549C123.979 60.4549 125.07 59.3607 125.07 58.011C125.07 56.6613 123.979 55.5671 122.632 55.5671C121.285 55.5671 120.193 56.6613 120.193 58.011C120.193 59.3607 121.285 60.4549 122.632 60.4549Z" fill="#00173B" />
    <path d="M106.008 53.6506C105.739 53.8038 105.433 53.9205 105.127 54.0008C102.143 54.7741 98.3944 54.5115 95.9196 52.5126C95.5483 52.2134 95.199 51.856 95.017 51.411C94.4711 50.0613 95.9123 49.2224 96.975 48.8503C99.2897 48.0333 102.092 48.4199 104.305 49.3902C105.28 49.8206 106.467 50.4845 106.874 51.535C107.253 52.5126 106.758 53.2275 106.008 53.6506Z" fill="#00173B" />
    <path d="M119.061 53.6236C119.33 53.7768 119.636 53.8936 119.942 53.9738C122.926 54.7471 126.675 54.4845 129.149 52.4856C129.521 52.1865 129.87 51.829 130.052 51.384C130.598 50.0344 129.157 49.1954 128.094 48.8234C125.779 48.0063 122.977 48.3929 120.764 49.3632C119.789 49.7936 118.602 50.4575 118.195 51.508C117.816 52.4856 118.311 53.2005 119.061 53.6236Z" fill="#00173B" />
    <path d="M105.943 75.4684C105.943 75.4684 119.206 77.5111 120.145 75.4684H105.943Z" fill="#D1DBFF" />
    <path d="M105.943 75.4684C105.943 75.4684 119.206 77.5111 120.145 75.4684" stroke="#00173B" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M112.978 52.0484C112.978 53.5585 112.978 55.0686 113 56.5788C113.022 57.9649 113.211 59.4166 112.687 60.737C112.141 62.1231 111.035 62.9256 110.11 64.0418C109.208 65.1288 108.567 66.3179 109.477 67.6092C109.928 68.2439 110.547 68.7983 111.079 69.3673C111.726 70.0677 112.374 70.7753 113.022 71.4757" fill="#D1DBFF" />
    <path d="M112.978 52.0484C112.978 53.5585 112.978 55.0686 113 56.5788C113.022 57.9649 113.211 59.4166 112.687 60.737C112.141 62.1231 111.035 62.9256 110.11 64.0418C109.208 65.1288 108.567 66.3179 109.477 67.6092C109.928 68.2439 110.547 68.7983 111.079 69.3673C111.726 70.0677 112.374 70.7753 113.022 71.4757" stroke="#00173B" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M101.869 43.2923C113.615 43.2923 123.138 33.7485 123.138 21.9755C123.138 10.2026 113.615 0.658813 101.869 0.658813C90.1221 0.658813 80.5996 10.2026 80.5996 21.9755C80.5996 33.7485 90.1221 43.2923 101.869 43.2923Z" fill="#F8F8F8" />
    <path d="M73.2715 22.3528C74.4725 16.8813 82.3483 15.2764 85.9514 19.6754C88.761 22.827 87.7493 28.4297 83.8769 30.1733C82.6686 30.7058 81.271 30.7569 80.1719 30.217C82.5157 30.3484 84.4155 28.7872 85.1725 26.7226C87.611 19.0115 77.1948 16.2247 73.2715 22.3528Z" fill="white" />
    <path d="M123.136 43.2923C134.883 43.2923 144.405 33.7485 144.405 21.9755C144.405 10.2026 134.883 0.658813 123.136 0.658813C111.39 0.658813 101.867 10.2026 101.867 21.9755C101.867 33.7485 111.39 43.2923 123.136 43.2923Z" fill="#F8F8F8" />
    <path d="M85.71 24.083C89.6479 12.0166 108.304 13.7748 109.112 26.8625C109.366 31.8524 106.004 37.0758 100.981 38.0972C97.4072 38.834 93.0762 36.3171 93.4183 32.3412C93.513 29.6128 95.9878 27.2491 98.6664 27.2783C94.8741 27.789 92.9525 32.5892 95.9805 35.1353C97.662 36.4411 100.188 36.4849 102 35.3687C106.994 32.5747 107.983 25.0387 103.878 21.0409C98.7174 15.7372 89.1529 17.6121 85.7172 24.083H85.71Z" fill="white" />
    <path d="M139.824 23.3495C136.382 16.8786 126.817 15.0037 121.656 20.3074C117.551 24.3052 118.541 31.8339 123.534 34.6353C125.347 35.7515 127.872 35.7077 129.554 34.4018C132.582 31.8558 130.66 27.0628 126.868 26.5448C129.547 26.5157 132.029 28.8793 132.116 31.6077C132.451 35.5764 128.127 38.0932 124.553 37.3637C119.538 36.3497 116.168 31.119 116.423 26.129C117.223 13.0413 135.887 11.2832 139.824 23.3495Z" fill="white" />
    <path d="M153.097 22.8015C149.166 16.6808 138.75 19.4603 141.196 27.1714C141.953 29.2359 143.853 30.7971 146.197 30.6658C145.097 31.1983 143.7 31.1546 142.492 30.622C138.619 28.8784 137.607 23.2757 140.417 20.1241C144.027 15.7251 151.903 17.3301 153.097 22.8015Z" fill="white" />
    <path d="M20.9043 204.942C20.9043 206.27 28.882 207.342 38.7231 207.342C48.5642 207.342 56.5419 206.27 56.5419 204.942V204.927H20.9043V204.942Z" fill="#102B09" />
    <path d="M56.5346 198.112H20.9043V204.933C20.9698 203.612 28.9184 202.547 38.7158 202.547C48.5132 202.547 56.4618 203.612 56.5273 204.933V198.112H56.5346Z" fill="#102B09" />
    <path d="M38.7158 202.545C28.9184 202.545 20.9698 203.61 20.9043 204.931H56.5346C56.4691 203.61 48.5205 202.545 38.7231 202.545H38.7158Z" fill="#102B09" />
    <path d="M38.7153 201.28C48.5563 201.28 56.5341 200.025 56.5341 198.478C56.5341 196.931 48.5563 195.677 38.7153 195.677C28.8742 195.677 20.8965 196.931 20.8965 198.478C20.8965 200.025 28.8742 201.28 38.7153 201.28Z" fill="#DAFEA4" />
    <path d="M55.0639 142.925C55.6899 141.969 61.9498 144.975 69.0467 149.636C76.1437 154.305 81.3845 158.857 80.7585 159.82C80.7585 159.82 80.7585 159.828 80.7512 159.835L55.0566 142.939C55.0566 142.939 55.0566 142.932 55.0566 142.925H55.0639Z" fill="#DAFEA4" />
    <path d="M77.5344 164.743L51.8398 147.847L55.0571 142.93C54.4821 143.915 59.7084 148.453 66.7762 153.1C73.844 157.747 80.0748 160.745 80.7517 159.826L77.5344 164.743Z" fill="#DAFEA4" />
    <path d="M66.7818 153.1C59.714 148.453 54.4877 143.915 55.0628 142.93L80.7574 159.826C80.0877 160.745 73.8497 157.747 66.7818 153.1Z" fill="#DAFEA4" />
    <path d="M77.4712 164.856C78.0975 163.9 72.8533 159.342 65.7579 154.676C58.6626 150.01 52.4029 147.003 51.7766 147.96C51.1503 148.916 56.3945 153.474 63.4898 158.14C70.5852 162.806 76.8448 165.813 77.4712 164.856Z" fill="#102B09" />
    <path d="M65.6493 174.429L111.61 204.652C112.763 205.41 114.31 205.09 115.065 203.936C115.82 202.782 115.498 201.232 114.345 200.474L68.3846 170.25C67.2317 169.492 65.6848 169.813 64.9295 170.967C64.1742 172.121 64.4965 173.67 65.6493 174.429Z" fill="#CFFDFF" stroke="#CFFDFF" stroke-width="1.02231" stroke-miterlimit="10" />
    <path d="M41.067 171.297L59.8115 183.623L73.5638 162.617L54.8193 150.29L41.067 171.297Z" fill="#DAFEA4" />
    <path d="M73.4155 162.66C73.8456 162.003 70.0226 158.727 64.8767 155.343C59.7308 151.959 55.2106 149.749 54.7806 150.406C54.3506 151.063 58.1735 154.338 63.3194 157.722C68.4653 161.106 72.9855 163.317 73.4155 162.66Z" fill="#DAFEA4" />
    <path opacity="0.2" d="M61.0922 182.006L44.9112 165.504L41.7812 170.217L61.0922 182.006Z" fill="#102B09" />
    <path d="M37.2475 167.794C37.8735 166.838 44.1334 169.844 51.2303 174.506C58.3273 179.175 63.5681 183.727 62.9421 184.69C62.9421 184.69 62.9421 184.697 62.9348 184.704L37.2402 167.809C37.2402 167.809 37.2402 167.801 37.2402 167.794H37.2475Z" fill="#DAFEA4" />
    <path d="M59.7102 189.623L34.0156 172.728L37.2329 167.811C36.6579 168.795 41.8841 173.333 48.952 177.98C56.0198 182.627 62.2506 185.626 62.9275 184.706L59.7102 189.623Z" fill="#DAFEA4" />
    <path d="M48.9566 177.981C41.8888 173.334 36.6625 168.796 37.2376 167.812L62.9322 184.707C62.2625 185.627 56.0245 182.628 48.9566 177.981Z" fill="#DAFEA4" />
    <path d="M59.7116 189.618C60.1196 188.995 54.6984 184.707 47.603 180.041C40.5077 175.375 34.425 172.098 34.017 172.721C33.6091 173.344 39.0302 177.632 46.1256 182.298C53.2209 186.964 59.3036 190.241 59.7116 189.618Z" fill="#102B09" />
    <path d="M140.58 202.022C140.58 202.022 128.686 206.232 121.342 202.723C121.342 202.723 113.299 197.798 105.598 204.116H79.5391L96.8556 187.643C96.8556 187.643 107.527 185.717 120.119 188.519C120.119 188.519 130.943 190.437 134.087 188.11C134.087 188.11 141.803 195.712 140.58 202.022Z" fill="#D1DBFF" />
    <path d="M139.854 203.126C139.854 203.126 134.744 191.774 127.145 189.148L133.499 184.72C133.499 184.72 145.378 187.339 148.137 204.125L139.854 203.126Z" fill="#F8F8F8" />
  </svg>
</template>
