import type { NodeType } from './doc'

export const NODE_TYPE_MAPPING = {
  law: 'title',
  chapter: 'chapter',
  section: 'paragraph',
  subsection: 'part',
  del: 'del',
  paragraph: 'paragraph',
  note: 'note',
} as const satisfies Record<NodeType, string>
